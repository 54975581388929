import PropTypes from 'prop-types';
//
import Main from './main';
import Header from './header';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  return (
    <>
      <Header />

      <Main>{children}</Main>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
