import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { HOST_API } from "src/config-global";

export const uri = `${HOST_API}/app/aggregates/graphql`;
const link = new HttpLink({ uri })

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
