// ----------------------------------------------------------------------

const ROOTS = {
  DASHBOARD: '/',
};

// ----------------------------------------------------------------------

export const paths = {
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
  },
};
